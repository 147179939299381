var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-space-between",class:{ 'w-custom-auto': _vm.widthCustom },attrs:{"data-testid":"ex-searchbar"}},[_c('div',{staticClass:"col filter-button",class:{
      'col-6': _vm.add,
      'col-8': !_vm.add
    }},[(_vm.counterSection)?_c('CheckpointsCounterLevel',{attrs:{"apprenticeCount":_vm.levelCounter.Apprentice,"professionalCount":_vm.levelCounter.Professional,"coachCount":_vm.levelCounter.Coach,"masterCount":_vm.levelCounter.Master},on:{"input":_vm.filterTypes}}):_vm._e(),_vm._l((_vm.filteredChipText),function(text,index){return _c('v-chip',{key:index,staticClass:"search-bar-filter",attrs:{"color":"#fff","label":""}},[_vm._v(" "+_vm._s(text)+" "),_c('img',{staticClass:"search-cross-icon",attrs:{"src":require(`@components/assets/cross-icon.svg`),"alt":"icon"},on:{"click":function($event){return _vm.removeFilter(index)}}})])}),_c('button',{class:_vm.classes,attrs:{"type":_vm.type},on:{"click":_vm.onClick}},[_c('img',{staticClass:"arrow-icon",attrs:{"src":require(`@components/assets/filter-icon.svg`),"alt":"icon"}}),_c('span',{staticClass:"filter"},[_vm._v(_vm._s(_vm.label))])])],2),(!_vm.inputBox)?_c('div',{staticClass:"col d-flex search-button",class:{
      'col-6': _vm.add,
      'col-4': !_vm.add
    }},[_c('ExTextInput',{staticClass:"filter-input-search",attrs:{"placeholder":"Search","icon":"interface_search","clearText":_vm.clearText,"value":_vm.valueSearch},on:{"input":_vm.handleInput}}),(_vm.add)?_c('MyButton',{staticClass:"ml-2 search-add-btn",attrs:{"label":_vm.btnText,"icon":_vm.actionButtonIcon,"normal":"normal-hover"},on:{"onClick":function($event){return _vm.$emit('onClick', 'add')}}}):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }