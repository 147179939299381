export default {
  methods: {
    getOrgObjectDropdownItems(data) {
      let items = []

      if (!data || !data.hasOwnProperty('status')) {
        return items;
      }

      if (data.status == "draft") {
        items = [
          {
            name: 'Activate',
            value: 'activate_action',
            icon: 'icons/dropdown/activate.svg',
            order: 1
          },
          {
            name: 'Rename',
            value: 'rename_action',
            icon: 'icons/dropdown/rename.svg',
            order: 2
          },
          {
            name: 'Delete',
            value: 'delete_action',
            icon: 'icons/dropdown/delete.svg',
            negative: true,
            order: 3,
          },
          {
            name: 'Clone',
            value: 'cloned_action',
            icon: 'icons/dropdown/clone.png',
            order: 4,
          },
        ]
      } else if (data.status === "active") {
        if (data.assigned_employees && data.assigned_employees.length === 0) {
          items = [
            {
              name: 'Retire',
              value: 'retire_action',
              icon: 'icons/dropdown/activate.svg',
              order: 1
            },
            ...items
          ];
        }
        items = [
          ...items,
          {
            name: 'De-Activate',
            value: 'deactivate_action',
            icon: 'icons/dropdown/activate.svg',
            order: 2
          },
          {
            name: 'Attach Role',
            value: 'attach_position_role',
            icon: 'icons/dropdown/attach.svg',
            order: 3
          },
          {
            name: 'Rename',
            value: 'rename_action',
            icon: 'icons/dropdown/rename.svg',
            order: 4
          },
          {
            name: 'Clone',
            value: 'cloned_action',
            icon: 'icons/dropdown/clone.png',
            order: 5
          }
        ];
      } else if (data.status == "inactive") {
        items = [
          {
            name: 'Re-Activate',
            value: 'reactivate_action',
            icon: 'icons/dropdown/activate.svg',
            order: 1
          },
          {
            name: 'Rename',
            value: 'rename_action',
            icon: 'icons/dropdown/rename.svg',
            order: 2
          },
          {
            name: 'Clone',
            value: 'cloned_action',
            icon: 'icons/dropdown/clone.png',
            order: 3,
          },
        ]
      } else if (data.status == "retired") {
        items = [
          {
            name: 'Re-Activate',
            value: 'reactivate_action',
            icon: 'icons/dropdown/activate.svg',
            order: 1
          },
          {
            name: 'Rename',
            value: 'rename_action',
            icon: 'icons/dropdown/rename.svg',
            order: 2
          },
          {
            name: 'Clone',
            value: 'cloned_action',
            icon: 'icons/dropdown/clone.png',
            order: 3,
          },
        ]
      }
      return items;
    },
    getEmployeeDropdownItems(status, access = "no") {
      let items = []
      if (status == "draft") {
        items = [
          {
            name: 'Activate',
            value: 'activate_action',
            icon: 'icons/dropdown/activate.svg',
            order: 1
          },
          {
            name: 'Delete',
            value: 'delete_action',
            icon: 'icons/dropdown/delete.svg',
            negative: true,
            order: 2,
          },
          {
            name: 'Edit',
            value: 'edit_action',
            icon: 'icons/dropdown/edit.svg',
            order: 3
          },
          {
            name: 'Change Picture',
            value: 'change_picture_action',
            icon: 'icons/dropdown/change_picture.svg',
            order: 4,
          },
        ]
      } else if (status == "active") {
        let giveAccessItem = {
          name: 'Give Access',
          value: 'give_access_action',
          icon: 'icons/dropdown/give_access.svg',
          order: 1
        }
        let suspendAccessItem = {
          name: 'Suspend Access',
          value: 'suspend_access_action',
          icon: 'icons/dropdown/suspend_access.svg',
          order: 2,
          negative: true
        }
        let giveBackAccessItem = {
          name: 'Give Back Access',
          value: 'give_back_access_action',
          icon: 'icons/dropdown/give_access.svg',
          order: 3
        }
        items = [
          {
            name: 'Terminate',
            value: 'terminate_action',
            icon: 'icons/dropdown/activate.svg',
            order: 1
          },
          {
            name: 'Edit',
            value: 'edit_action',
            icon: 'icons/dropdown/edit.svg',
            order: 2
          },
          {
            name: 'Change Picture',
            value: 'change_picture_action',
            icon: 'icons/dropdown/change_picture.svg',
            order: 3,
          },
          access == "no" ? giveAccessItem : access == "yes" ? suspendAccessItem : giveBackAccessItem
        ]
      } else if (status == "terminated") {
        items = [
          {
            name: 'Re-Activate',
            value: 'reactivate_action',
            icon: 'icons/dropdown/activate.svg',
            order: 1
          },
          {
            name: 'Edit',
            value: 'edit_action',
            icon: 'icons/dropdown/edit.svg',
            order: 2
          },
          {
            name: 'Change Picture',
            value: 'change_picture_action',
            icon: 'icons/dropdown/change_picture.svg',
            order: 3
          }
        ]
      }
      return items;
    },
    getReadinessEmployeeDropdownItems(status) {
      let items = []
      if (status == "active") {
        items = [
          {
            name: 'Terminate',
            value: 'terminate_action',
            icon: 'icons/dropdown/activate.svg',
            order: 1
          }
        ]
      }
      return items;
    },
    getReadinessCatalogsRoleItem(item) {
      const activateItem = {
        name: 'Activate',
        value: 'activate_role',
        icon: 'icons/dropdown/activate.svg',
        order: 1
      }
      const reActivateItem = {
        name: 'Re-activate',
        value: 're_activate_role',
        icon: 'icons/dropdown/activate.svg',
        order: 1
      }
      const retireItem = {
        name: 'Retire',
        value: 'retired_role',
        icon: 'icons/dropdown/activate.svg',
        order: 1
      }
      const attachResponsibilityItem = {
        name: 'Attach Responsibility',
        value: 'attach_responsibility_role',
        icon: 'icons/dropdown/attach.svg',
        order: 2
      }
      const renameItem = {
        name: 'Rename',
        value: 'rename_role',
        icon: 'icons/dropdown/rename.svg',
        order: 3
      }
      const deleteItem = {
        name: 'Delete',
        value: 'delete_role',
        icon: 'icons/dropdown/delete.svg',
        negative: true,
        order: 4
      }
      const deActivateItem = {
        name: 'De-Activate',
        value: 'de_activate_role',
        icon: 'icons/dropdown/deactive.png',
        order: 1
      }
      // const detachItem = {
      //   name: 'Detach from Position',
      //   value: 'detach_role',
      //   icon: 'icons/dropdown/detach.svg',
      //   order: 1
      // }
      const roleItems = [];
      if (item?.status && item?.status.toLowerCase() == "draft") {
        roleItems.push(activateItem);
        if (item?.info?.attachments?.length > 0) {
          // roleItems.push(detachItem);
          roleItems.push(attachResponsibilityItem);
        } else {
          roleItems.push(attachResponsibilityItem);
          // roleItems.push(detachItem);
        }
      }

      if (item?.status && item?.status.toLowerCase() == "active") {
        roleItems.push(retireItem);
        roleItems.push(deActivateItem);
        // roleItems.push(detachItem);
        roleItems.push(attachResponsibilityItem);
      }

      if (item?.status && item?.status.toLowerCase() == "retired") {
        roleItems.push(reActivateItem);
      }

      if (item?.status && item?.status.toLowerCase() == "inactive") {
        roleItems.push(reActivateItem);
      }
      roleItems.push(renameItem);
      if (item?.status && item?.status.toLowerCase() == "draft" && item?.info && item?.info?.attachments?.length == 0) {
        roleItems.push(deleteItem)
      }
      if (item?.status && item?.status.toLowerCase() == "draft" && item && item?.attachments?.length == 0) {
        roleItems.push(deleteItem)
      }
      return roleItems
    },
    getReadinessCatalogsRessponsibilitiesItem(item) {
      const responsibilitiesItems = []
      const activateItem = {
        name: 'Activate',
        value: 'activate_responsibility',
        icon: 'icons/dropdown/activate.svg',
        order: 1
      }
      const deActivateItem = {
        name: 'De-activate',
        value: 'de_activate_responsibility',
        icon: 'icons/dropdown/deactive.png',
        order: 1
      }
      const reActivateItem = {
        name: 'Re-activate',
        value: 're_activate_responsibility',
        icon: 'icons/dropdown/activate.svg',
        order: 1
      }
      const retireItem = {
        name: 'Retire',
        value: 'retire_responsibility',
        icon: 'icons/dropdown/activate.svg',
        order: 2
      }
      const attachKnowledgeItem = {
        name: 'Attach Knowledge',
        value: 'attach_knowledge',
        icon: 'icons/dropdown/attach.svg',
        order: 3
      }
      const renameItem = {
        name: 'Rename',
        value: 'rename_responsibility',
        icon: 'icons/dropdown/rename.svg',
        order: 4
      }
      const deleteResponsibilityItem = {
        name: 'Delete',
        value: 'delete_responsibility',
        icon: 'icons/dropdown/delete.svg',
        negative: true,
        order: 5
      }
      // const detachResponsibilityItem = {
      //   name: 'Detach from Role',
      //   value: 'detach_responsibility',
      //   icon: 'icons/dropdown/detach.svg',
      //   order: 2
      // }
      if (item.status && item.status.toLowerCase() == "draft") {
        responsibilitiesItems.push(activateItem);
        // responsibilitiesItems.push(detachResponsibilityItem);
      } else if (item.status && item.status.toLowerCase() == "active") {
        responsibilitiesItems.push(retireItem);
        // responsibilitiesItems.push(detachResponsibilityItem);
        responsibilitiesItems.push(deActivateItem);
      } else if (item?.status && item?.status.toLowerCase() == "retired") {
        responsibilitiesItems.push(reActivateItem);
      } else if (item?.status && item?.status.toLowerCase() == "inactive") {
        responsibilitiesItems.push(reActivateItem);
      }
      // responsibilitiesItems.push(attachKnowledgeItem); hide for now
      responsibilitiesItems.push(renameItem);
      if (item?.status && item?.status.toLowerCase() == "draft" && item?.data && item?.data?.attachments?.length == 0) {
        responsibilitiesItems.push(deleteResponsibilityItem);
      }

      return responsibilitiesItems;
    },
    getAdminUsersItems(item, currentUser = null) {
      if (!currentUser) return
      const resendInviteItem = {
        name: 'Resend Invite',
        value: 'resent_invite_action',
        icon: 'icons/dropdown/resend.svg',
        order: 1
      };
      const cancelInviteItem = {
        name: 'Cancel Invite',
        value: 'cancel_invite_action',
        icon: 'icons/dropdown/delete.svg',
        negative: true,
        order: 2,
      };
      const renameItem = {
        name: 'Rename',
        value: 'rename_action',
        icon: 'icons/dropdown/rename.svg',
        order: 3,
      };
      const closeItem = {
        name: 'Close',
        value: 'close_action',
        icon: 'icons/dropdown/activate.svg',
        negative: true,
        order: 4,
      };
      const unCloseItem = {
        name: 'Un-Close',
        value: 'unclose_action',
        icon: 'icons/dropdown/activate.svg',
        order: 5,
      };
      const blockItem = {
        name: 'Block',
        value: 'block_action',
        icon: 'icons/dropdown/activate.svg',
        negative: true,
        order: 6,
      };
      const unBlockItem = {
        name: 'Un-Block',
        value: 'unblock_action',
        icon: 'icons/dropdown/activate.svg',
        order: 7,
      };
      const deleteItem = {
        name: 'Delete',
        value: 'delete_action',
        icon: 'icons/dropdown/delete.svg',
        negative: true,
        order: 8,
      };

      const userStatus = item.state || "draft";
      const isOwner = item.is_owner;
      const isCoOwner = item.is_co_owner;
      const optionsArray = [];

      if (userStatus == "invited") {
        if (item.is_superuser) {
          optionsArray.push(renameItem);
          if (currentUser.id !== item.id) {
            optionsArray.push(deleteItem);
          }
        } else {
          optionsArray.push(resendInviteItem);
          optionsArray.push(cancelInviteItem);
          optionsArray.push(renameItem);
          optionsArray.push(deleteItem);
        }
      } else if (userStatus == "operational") {
        optionsArray.push(renameItem);
        if (currentUser.id !== item.id) {
          optionsArray.push(closeItem);
          optionsArray.push(blockItem);
        }
      } else if (userStatus == "closed") {
        if (currentUser.id !== item.id) {
          optionsArray.push(unCloseItem);
        }
      } else if (userStatus == "blocked") {
        optionsArray.push(renameItem);
        if (currentUser.id !== item.id) {
          optionsArray.push(unBlockItem);
        }
      }

      return optionsArray;
    },
    getAdminAccountItems(item) {
      const openItem = {
        name: 'Activate',
        value: 'admin_open_account',
        icon: 'icons/dropdown/activate.svg',
        order: 1
      }
      const deleteItem = {
        name: 'Delete',
        value: 'admin_delete_account',
        icon: 'icons/dropdown/delete.svg',
        negative: true,
        order: 2,
      }
      const renameItem = {
        name: 'Rename',
        value: 'admin_rename_account',
        icon: 'icons/dropdown/rename.svg',
        order: 3,
      }
      const suspendItem = {
        name: 'Suspend',
        value: 'admin_suspend_account',
        icon: 'icons/dropdown/delete.svg',
        negative: true,
        order: 4,
      }
      const changeOwnerItem = {
        name: 'Change Owner',
        value: 'admin_change_account_owner',
        icon: 'icons/dropdown/change_owner.svg',
        order: 5,
      }
      const reOpenItem = {
        name: 'Re-Open',
        value: 'admin_reopen_account',
        icon: 'icons/dropdown/activate.svg',
        order: 6,
      }
      const closeItem = {
        name: 'Close',
        value: 'admin_close_account',
        icon: 'icons/dropdown/activate.svg',
        order: 7,
      }
      const cancelInviteItem = {
        name: 'Cancel Invite',
        value: 'cancel_invite_action',
        icon: 'icons/dropdown/delete.svg',
        negative: true,
        order: 1,
      }
      const accountStatus = item?.status || "draft";
      const optionsArray = [];
      if (accountStatus === "draft") {
        optionsArray.push(openItem);
        optionsArray.push(deleteItem);
        optionsArray.push(renameItem);
      } else if (accountStatus === "open") {
        optionsArray.push(suspendItem);
        optionsArray.push(closeItem);
        optionsArray.push(renameItem);
        optionsArray.push(changeOwnerItem);
      } else if (accountStatus === "suspended") {
        optionsArray.push(reOpenItem);
        optionsArray.push(closeItem);
        optionsArray.push(renameItem);
      } else if (accountStatus === "closed") {
        optionsArray.push(reOpenItem);
        optionsArray.push(renameItem);
      } else if (["pending", "pending activation", "pending-activation"].includes(accountStatus)) {
        optionsArray.push(cancelInviteItem);
        optionsArray.push(renameItem);
      }
      return optionsArray;
    },
    getSettingsUsersItems(item) {
      const resendInviteItem = {
        name: 'Resend Invite',
        value: 'resend_invite',
        icon: 'icons/dropdown/resend.svg',
        order: 1
      }
      const cancelInviteItem = {
        name: 'Cancel Invite',
        value: 'cancel_invite_action',
        icon: 'icons/dropdown/delete.svg',
        negative: true,
        order: 2,
      }
      const changePermissionsItem = {
        name: 'Change permissions',
        value: 'change_permissions_action',
        icon: 'icons/dropdown/give_access.svg',
        order: 3,
      }
      const renameItem = {
        name: 'Rename',
        value: 'rename_action',
        icon: 'icons/dropdown/rename.svg',
        order: 4,
      }
      const suspendItem = {
        name: 'Suspend',
        value: 'suspend',
        icon: 'icons/dropdown/suspend_access.svg',
        negative: true,
        order: 8,
      }
      const giveBackItem = {
        name: 'Give back access',
        value: 'give_back_access',
        icon: 'icons/dropdown/give_access.svg',
        order: 4,
      }
      const transferOwnershipItem = {
        name: 'Transfer ownership',
        value: 'transfer_ownership_action',
        icon: 'icons/dropdown/give_access.svg',
        order: 5,
      }
      const userStatus = item?.user?.state || "invited";
      const isCoOwner = item.is_co_owner;
      const isOwner = item.is_owner;
      const isSuspended = item.is_suspended;
      const isAppUser =
        item.has_readiness_access || item.has_knowledge_access ||
        item.has_performance_access || item.has_compensation_access

      const optionsArray = [];

      if (isOwner) {
        optionsArray.push(transferOwnershipItem);
      } else {
        if (isCoOwner) {
          if (userStatus.toLowerCase() == "invited") {
            optionsArray.push(resendInviteItem);
            optionsArray.push(cancelInviteItem);
            optionsArray.push(changePermissionsItem);
            optionsArray.push(renameItem);
          } else if (userStatus.toLowerCase() == "operational" && !isSuspended) {
            optionsArray.push(suspendItem);
            optionsArray.push(changePermissionsItem);
            optionsArray.push(renameItem);
          } else if (isSuspended) {
            optionsArray.push(giveBackItem);
            optionsArray.push(renameItem);
          }
        } else if (isAppUser) {
          if (userStatus.toLowerCase() == "invited") {
            optionsArray.push(resendInviteItem);
            optionsArray.push(cancelInviteItem);
            optionsArray.push(changePermissionsItem);
            optionsArray.push(renameItem);
          } else if (userStatus.toLowerCase() == "operational" && !isSuspended) {
            optionsArray.push(suspendItem);
            optionsArray.push(changePermissionsItem);
            optionsArray.push(renameItem);
          } else if (isSuspended) {
            optionsArray.push(giveBackItem);
            optionsArray.push(renameItem);
          }
        }
      }
      return optionsArray;
    },
  }
};
