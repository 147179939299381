var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[_c('StandarPageWithList',{attrs:{"title":"All Users","activeNo":_vm.activeSystemUsers,"empty":!_vm.systemUsers.length},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('span')]},proxy:true},{key:"empty-content",fn:function(){return [_c('div',{staticClass:"d-flex flex-column align-center"},[_c('h3',{staticClass:"mb-2"},[_vm._v("No users found")]),_c('p',{staticClass:"text-center mb-8"},[_vm._v(" You need some users in your "),_c('br'),_vm._v(" organization ")]),_c('Button',{staticClass:"mb-6 admin-empty-content-user-add",attrs:{"primary":true,"label":"+ Add System Admin","size":"small"},on:{"onClick":function($event){return _vm.addUser('add')}}})],1)]},proxy:true}])},[_c('div',{staticClass:"px-5 mb-5"},[_c('SearchBar',{staticClass:"admin-user-searchbar",attrs:{"type":"button","label":_vm.appliedFilters.length > 0 ? 'More Filters' : 'Filters',"length":_vm.appliedFilters.length,"chipText":_vm.appliedFilters,"ghostText":"Search","add":true,"btn-text":"Add System Admin","valueSearch":_vm.filterText},on:{"onSearch":_vm.onFilter,"onClick":_vm.addUser,"removeFilter":_vm.removeFilter}})],1),_c('DataTable',{attrs:{"pagination":_vm.paginationModel,"headers":_vm.headers,"data":_vm.filteredUsers},on:{"onRequest":_vm.requestData},scopedSlots:_vm._u([{key:"no-data",fn:function(){return undefined},proxy:true},{key:"user",fn:function({ row }){return [_c('span',{staticClass:"admin-name",on:{"click":function($event){return _vm.gotoAccounts(row)}}},[_c('UserAdminCard',{staticClass:"my-4",attrs:{"fullName":row.user.data.first_name + ' ' + row.user.data.last_name,"email":row.user.data.email}})],1)]}},{key:"account_access",fn:function({ row }){return [_c('div',[(
              row.user.data.account_permissions &&
              row.user.data.account_permissions.length > 0
            )?_c('ExUserAccessBadge',{attrs:{"account_name":row.user.data.account_permissions[0].account_name,"permission":_vm.getPermissionType(row.user.data.account_permissions[0]),"access":_vm.getAccessType(row.user.data.account_permissions[0]),"account_status":"Open","accounts_count":row.user.data.account_permissions.length - 1}}):_vm._e()],1)]}},{key:"lastLogin",fn:function({ row }){return [_c('div',{staticClass:"last-login-text"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(row?.user?.data?.last_login || ""))+" ")])]}},{key:"status",fn:function({ row }){return [_c('UserAdminStatusBadge',{attrs:{"status":row.user.data.state[0].toUpperCase() +
            row.user.data.state.substring(1)}})]}},{key:"actions",fn:function({ row }){return [_c('div',[_c('DropDownMenu',{attrs:{"is-open":false,"dropdown-items":_vm.getAdminUsersItems(row.user.data, _vm.appUser),"scrollContainerClass":".ex-main-container","attachSelector":".ex-main-container","absolute":true},on:{"onClick":function($event){return _vm.triggerDropdownAction(row.user.data, $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z","stroke":"#344054","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z","stroke":"#344054","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z","stroke":"#344054","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]},proxy:true}],null,true)})],1)]}}])})],1),(_vm.userAccountActionDialogObject?.show)?_c('ExActionsPopUp',{attrs:{"headline":_vm.userAccountActionDialogObject?.title,"htmlInfo":_vm.userAccountActionDialogObject.description,"actionLabel":_vm.userAccountActionDialogObject?.actionText,"value":_vm.userAccountActionDialogObject?.show,"primary":_vm.userAccountActionDialogObject.primary,"termination":_vm.userAccountActionDialogObject.termination,"destructive":_vm.userAccountActionDialogObject.destructive ?? false,"avatar":{
      picture: '',
      firstName: _vm.currentUser?.first_name || '',
      lastName: _vm.currentUser?.last_name || '',
    }},on:{"onAction":_vm.OnActionUserAccountDialog,"input":_vm.OnCloseUserAccountDialog}}):_vm._e(),_c('DeleteAdminUserPopup',{attrs:{"show":_vm.userActions.showDeleteUserPopup,"user":{
      id: _vm.currentUser?.id || 0,
      firstName: _vm.currentUser?.first_name || '',
      lastName: _vm.currentUser?.last_name || '',
    }},on:{"onDeleteAdminUser":_vm.deleteAdminUser,"onClose":_vm.onCloseActionPopup}}),_c('ExCreateUserWizard',{attrs:{"dialog":_vm.addUserDialog,"accessLevel":'owner',"isSuperUser":_vm.isSuperUser,"allUsers":_vm.systemUsers},on:{"created":_vm.onCreatedUser,"dialog-closed":function($event){_vm.addUserDialog = false}}}),_c('RenameAdminUserPopup',{attrs:{"current-user":_vm.currentUser,"existingNames":_vm.filteredUsers.map((el) => el.first_name)},on:{"input":_vm.onCloseActionPopup,"onSave":_vm.renameAdminUser},model:{value:(_vm.userActions.showRenameUserPopup),callback:function ($$v) {_vm.$set(_vm.userActions, "showRenameUserPopup", $$v)},expression:"userActions.showRenameUserPopup"}}),_c('CancelInviteAdminUserPopup',{attrs:{"show":_vm.userActions.showCancelInviteUserPopup,"user":{
      id: _vm.currentUser?.id || 0,
      firstName: _vm.currentUser?.first_name || '',
      lastName: _vm.currentUser?.last_name || '',
    }},on:{"onCancelInviteAdminUser":_vm.cancelInviteAdminUser,"onClose":_vm.onCloseActionPopup}}),_c('SearchModal',{attrs:{"dialog":_vm.showFilterDialog,"clearSelection":_vm.clearSelection,"filterType":"Users","filterGroups":_vm.filterGroups,"statusOptions":_vm.statusOptions,"modalWidth":620,"defaultSelected":_vm.appliedFilters},on:{"back":function($event){_vm.showFilterDialog = false},"checkedItemsChanged":_vm.filteredModalUsers}}),_c('ResendInviteAdminUserPopup',{attrs:{"show":_vm.userActions.showResendInviteUserPopup,"user":{
      id: _vm.currentUser?.id || 0,
      firstName: _vm.currentUser?.first_name || '',
      lastName: _vm.currentUser?.last_name || '',
    }},on:{"onResendInviteAdminUser":_vm.resendInviteAdminUser,"onClose":_vm.onCloseActionPopup}}),(_vm.filteredUsers?.length === 0 && _vm.systemUsers?.length != 0)?_c('NoRecordFound',{staticClass:"no-record-found account-not-found",attrs:{"title":_vm.title,"message":_vm.title,"btnTitle":'System Admin',"subtitle":'',"clearLabel":_vm.clearBtnLabel,"showClearAction":true,"description":_vm.emptyRecordDescription},on:{"onAction":function($event){return _vm.addUser('add')},"onClear":function($event){return _vm.clearSearchFilter()}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }